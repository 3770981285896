@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap');

html {
  scroll-behavior: smooth;
  font-family: 'Bahnschrift', 'Roboto', sans-serif;
}

body {
  background-color: #ecebf3;
}
